import { CanActivateFn, Router, ActivatedRouteSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

export const mobileRedirectGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  const isMobile = inject(BreakpointObserver).isMatched([Breakpoints.Handset]);
  return inject(Router).navigate([isMobile ? '/m' : '/d'], {
    queryParams: route.queryParams,
  });
};
