import {
  Component,
  OnInit,
  Inject,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { take, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';

import { SessionService } from '@services/session.service';
import { UserAction } from '@longnecktech/splash-commons-fe';
import { SpinService } from '@services/spin.service';
import { TranslationService } from '@services/translation.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('backgroundSound') backgroundSoundEl?: ElementRef;
  @ViewChild('spinSound') spinSoundEl?: ElementRef;
  notPaused$ = this.session.game$.pipe(map((game) => !game?.paused));
  backgroundSound$ = this.session.theme$.pipe(
    map((theme) => theme?.backgroundSoundAsset),
  );
  spinSound$ = this.session.theme$.pipe(map((theme) => theme?.spinSoundAsset));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private session: SessionService,
    private spinService: SpinService,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ) {
    window.addEventListener('beforeunload', () => {
      this.sendAction();
    });
  }

  ngOnInit(): void {
    this.checkRouting();
    this.initUser();
    this.updateBodyClass();
    this.sendAction();
  }

  ngAfterViewInit(): void {
    this.backgroundSound$
      .pipe(
        filter((sound) => !!sound?.url),
        take(1),
      )
      .subscribe(() => {
        if (this.backgroundSoundEl?.nativeElement) {
          const media = this.backgroundSoundEl.nativeElement;
          // the music in unmuted by default because we want it play
          // immediately on loading the app
          media.muted = false;
          media.play().catch(() => {
            // we can't start playing the background music without
            // the user's permission or first interaction
            console.log('Autoplay is not supported');
            // mute music
            this.session.toggleMuted(true);
          });

          this.session.isMusicMuted$
            .pipe(untilDestroyed(this))
            .subscribe((isMuted) => {
              media.muted = isMuted;
              media.play().catch(() => {});
            });
        }
      });
    this.spinService.spinClicked$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.session.isMusicMuted$),
        filter(([spin, isMuted]) => spin && !isMuted),
      )
      .subscribe(() => {
        if (this.backgroundSoundEl?.nativeElement) {
          const media = this.backgroundSoundEl.nativeElement;
          media.pause();
          media.currentTime = 0; // Reset the audio to the start
        }
        if (this.spinSoundEl?.nativeElement) {
          this.spinSoundEl.nativeElement.play();
        }
      });
  }

  private updateBodyClass(): void {
    const bodyClass = 'mobile-version';
    this.session.isMobile$.pipe(untilDestroyed(this)).subscribe((isMobile) => {
      if (isMobile) {
        this.document.body.classList.add(bodyClass);
      } else {
        this.document.body.classList.remove(bodyClass);
      }
    });
  }

  private initUser(): void {
    this.session.user$
      .pipe(
        filter((user) => !!user),
        take(1),
      )
      .subscribe((user) => {
        console.log('user logged in', user!.username);

        if (user?.blocked) {
          this.router.navigate(['/error'], {
            queryParams: {
              errorMsg: this.translationService.translate('error.blockedMsg'),
            },
          });
        }
      });
  }

  private checkRouting(): void {
    combineLatest([
      this.breakpointObserver.observe([Breakpoints.Handset]),
      this.route.queryParams,
    ])
      .pipe(
        untilDestroyed(this),
        filter(([, queryParams]) => !!Object.keys(queryParams).length),
        filter(() => !window.location.href.includes('/error')),
      )
      .subscribe(([result, queryParams]) => {
        if (result.matches) {
          this.session.setIsMobile(true);
          if (!window.location.href.includes('/m')) {
            this.router.navigate(['/m'], { queryParams });
          }
        } else if (!result.matches) {
          this.session.setIsMobile(false);
          if (!window.location.href.includes('/d')) {
            this.router.navigate(['/d'], { queryParams });
          }
        }
      });
  }

  private sendAction(): void {
    combineLatest([
      this.notPaused$,
      this.spinService.spinClicked$,
      this.spinService.allowedToSpin$,
    ])
      .pipe(
        filter(
          ([gameNotPaused, spinClicked, allowedToSpin]) =>
            !!gameNotPaused && allowedToSpin && !spinClicked,
        ),
        take(1),
        map(() => (new Date().getTime() - this.session.startTime) / 1000),
        switchMap((secondsOnPage) =>
          this.session.sendAction(UserAction.ABANDON_GAME, { secondsOnPage }),
        ),
      )
      .subscribe();
  }
}
